import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/translation.json';
import translationBG from './locales/bg/translation.json';
const availableLanguages = ['en', 'bg'];


i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            en: {
                translation: translationEN
            },

            bg: {
                translation: translationBG
            }      
        },
        //lng: "bg", // if you're using a language detector, do not define the lng option
        //lng: localStorage.getItem('lng') || 'en',
        fallbackLng: "en",

        interpolation: {
        escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });




export default i18n;